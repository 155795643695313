@import (reference) "./../../App.less";

.demo-panel {
  margin: 3em auto;
  max-width: @max-width;
  .rs-panel-body {
    padding: 0 @content-margin;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      margin: 0 auto;
      white-space: pre-wrap;
      font-size: 14px;
    }

    .demo-text {
      width: 80%;
      text-align: center;
      margin-bottom: 2em;
    }

    .demo-info {
      margin: 2em 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        i {
          margin-right: 0.5em;
        }
      }
    }

    .demo-preview img {
      width: 100%;
      margin: 2em 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .demo-panel {
    margin: 5em auto;
    .rs-panel-body {
      max-width: @max-width;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        margin: 0 auto;
      }

      .demo-info {
        margin: 2em 0;
        width: 62%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        > div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          i {
            margin-right: 0.5em;
            transform: scale(0.75);
          }
        }
      }

      .demo-preview img {
        margin: 2em;
        width: 70em;
        margin-bottom: 0;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;