@import (reference) "./../../App.less";

.intro-panel {
  margin: 0em 0em;
  border-radius: 0;

  .rs-panel-body {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    max-width: @max-width;
    margin: 0 auto;
    padding: 0;
  }

  .panel-text {
    padding: 20px;
    h1 {
      color: white;
    }
    h5:first-of-type {
      padding-top: 1.5em;
      color: white;
    }

    .brand-list {
      margin-top: 1em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      img {
        height: 3em;
      }
    }
  }
  .panel-img {
    position: relative;
    height: 100%;
    padding: 0 0.5em;
    margin-top: 2em;
    > img {
      width: 100%;
      margin: 4.5em auto;
    }
  }
  .ga4-ready {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
    border-radius: 6px;
    padding: 0 0.5em;
    width: 63%;
    margin: 0em auto;
    margin-top: -5.5em;
    img {
      width: 5em;
    }
    h4 {
      color: @font-highlight-color;
    }
  }
}

@media screen and (min-width: 1024px) {
  .intro-panel {
    margin: 0em 0em;

    .rs-panel-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 5em 0;
      padding-left: 4%;
    }

    .panel-text {
      max-width: 50%;
      padding: 0;
      h1 {
        margin-top: 0 !important;
      }
      h5 {
        padding-right: 1em;
      }
      .brand-list {
        margin-top: 2em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        img {
          height: 5em;
        }
      }
    }
    .panel-img {
      width: 55%;
      position: relative;
      height: 35em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 3em;
      margin-top: 0em;
      filter: drop-shadow(1px 2px 3px rgba(1, 1, 1, 0.5));
      > img {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .ga4-ready {
    margin-top: -6.5em !important;
    width: 65%;
    img {
      width: 7em !important;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;