@import (reference) "./../../App.less";

.solution-panel {
  .solution-img {
    width: 100%;
  }
  .solution-text {
    padding: 1em 3em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(239, 239, 239);
    background: linear-gradient(
      0deg,
      rgba(239, 239, 239, 1) 70%,
      rgba(2, 0, 36, 0) 100%
    );
    position: relative;
    padding-top: 15em;
    margin-top: -20em;

    h2 {
      text-align: center;
      color: black;
      font-size: 1.5em;
      line-height: 30px;
    }

    .rs-list {
      margin: 1em 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    .rs-list-item {
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0);
    }
    .rs-list-item-content {
      font-size: 14px;
      font-weight: bold;
    }
  }
}

@media screen and (min-width: 1024px) {
  display: flex;

  .solution-panel {
    .solution-img {
      width: 100%;
    }
    .solution-text {
      background: rgb(239, 239, 239);
      background: linear-gradient(
        0deg,
        rgba(239, 239, 239, 1) 50%,
        rgba(2, 0, 36, 0) 100%
      );
      padding-top: 15em;
      margin-top: -40em;
      h2 {
        font-size: 2em;
      }
      .rs-list {
        width: 80%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .rs-list-item-content {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;