@import (reference) "./../../App.less";

.testimony-card {
  width: 100%;
  margin: 1em 0em;
  background-color: white;

  .rs-panel-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .rs-container {
    flex: none;
  }

  .testimony-panel-text {
    flex-grow: 1;
  }
  .testimony-panel-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .testimony-img {
      background-size: contain;
      border: 2px solid @font-highlight-color;
      padding: 0.5em;
      width: 4em;
      height: 4em;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .company-logo {
      height: 3.5em;
    }
  }
  .testimony-panel-text {
    h5 {
      color: black;
      padding-bottom: 1em;
    }
  }

  .testimony-footer {
    border-top: 2px solid @font-highlight-color;
    padding-top: 1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h5 {
      color: black;
      line-height: 20px;
      font-weight: normal;
    }
    .testimony-job {
      font-weight: bold;
    }
    .testimony-details {
    }
  }
}

@media screen and (min-width: 1024px) {
  .testimony-card {
    margin: 1em auto;
    max-width: 50%;
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;