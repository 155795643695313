@import (reference) "./../../App.less";

.join-panel {
  border-radius: 0px;
  padding: 3em 0;
  position: relative;
  .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      padding: 0 @content-margin;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .panel-text {
    width: 100%;
  }
  .panel-img {
    width: 100%;
    margin-bottom: 2em;
    img {
      width: 100%;
      filter: drop-shadow(1px 2px 3px rgba(1, 1, 1, 0.5));
    }
  }
  .panel-text {
    align-items: center;
    .logo-img {
      width: 2em;
      margin-right: 0.5em;
      background-color: rgba(211, 211, 211, 0.192);
      border-radius: 6px;
      padding: 0.1em;
    }
    h3 {
      text-align: center;
      margin-bottom: 1em;
      color: white;
      strong {
        color: @font-highlight-color;
      }
    }
  }
  a {
    width: 60%;
  }
  button {
    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .join-panel {
    flex-direction: row;
    padding: 5em 0;
    .rs-panel-body {
      > div {
        flex-direction: row;
        align-items: center;
      }
    }
    .panel-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      h3 {
        width: 70%;
      }
      h3 {
      }
    }
    .panel-img {
      width: 50%;
      margin-bottom: 0;
    }
    a {
      width: 30%;
      font-size: 14px;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;