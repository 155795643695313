@import (reference) "./../../App.less";

.feature-point-card {
  margin: 2em 1em;

  width: 80%;
  .feature-panel-img {
    img {
      width: 7em;
      filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.25));
    }
  }
  .feature-panel-text {
    h3,
    h4 {
      text-align: center;
    }
    h4 {
      margin: 0;
      margin-top: 0.5em;
      color: white;
      font-weight: bold;
    }
    h5 {
      color: white;
      line-height: 15px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .feature-point-card {
    margin: 1em;
    width: 25%;
    margin-bottom: 0;
    .feature-panel-img {
      margin: 2em 0;
      img {
        width: 7em;
        filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.25));
      }
    }
    .feature-panel-text {
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;