@import (reference) "./../../App.less";

.page-nav {
  width: 100%;
  background-color: white;
  padding-top: 0;
  box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
  .rs-navbar.rs-navbar-default {
    max-width: @max-width;
    margin: 0 auto;
    padding: 0.5em 1em;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .rs-navbar::after,
  .rs-navbar::before {
    content: none;
    display: none;
  }
  .rs-navbar-body {
    display: flex;
    align-items: center;
    .rs-btn {
      i {
      }
    }
  }
}

.nav-header {
  height: initial;
  .nav-logo-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
  }

  .logo-img {
    margin-top: 0.5em;
    width: 2em;
  }

  h3 {
    margin-left: 0.2em;
    font-size: 1.5em;
  }
}

.main-nav {
  display: flex;
  flex-direction: row;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
  }

  button {
    color: black;

    &:hover,
    &:focus {
      color: @font-highlight-color;
      text-decoration: none;
    }
  }
  button:hover {
    text-decoration: none;
  }
}
.rs-drawer-bottom {
  max-height: 300px;
}
.rs-drawer-content {
  border-radius: 6px 6px 0px 0px;
}

.nav-drawer {
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  button:not(.button-primary):not(.button-secondary) {
    color: black;
    font-weight: normal;
    font-size: 16px;
    width: 100%;
  }

  .button-primary,
  .button-secondary {
    margin: 0.5em 0em;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .nav-header {
    .logo-img {
      width: 3em;
    }
    h3 {
      font-size: 2em;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;