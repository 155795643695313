@import (reference) "./../../App.less";

.feature-details-panel {
  &#mobile-features {
    .rs-panel-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;

      .feature-details-title {
        padding: 0 20px;
      }
    }
  }
  .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;

    .feature-details-title {
      text-align: center;
      margin-bottom: 1em;
      color: black;
    }

    .country-flag {
      width: 1.5em;
      border-radius: 6px;
      margin: 0 0.5em;
      border: 1px solid black;
    }

    .rs-table {
      .rs-table-cell-header .rs-table-cell-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .rs-table-affix-header.fixed {
        top: 66px !important;
      }
      .rs-table-row:not(:last-child) {
        .rs-table-cell:first-child {
          .rs-table-cell-content {
            word-break: break-word;
            display: flex;
            align-items: center;
          }
        }

        .rs-table-cell:not(:first-child) {
          .rs-table-cell-content {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .rs-table-cell-content {
        padding: 6px 10px;
        white-space: pre-wrap;
      }
      .rs-table-row:last-child {
        .rs-table-cell:not(:first-child):not(:nth-child(2)) > div {
          margin-left: -5px;
        }
        .rs-table-cell:nth-child(3) > div {
          border-bottom: 5px solid #0a5291;
        }
      }
      .rs-table-cell button {
        margin-top: 0.5em;
        width: 90% !important;
      }
      .rs-table-cell .rs-icon {
        font-weight: bold;
        color: @font-highlight-color;
        font-size: 20px;
      }

      .rs-table-row {
        &.rs-table-row-header {
          .rs-table-cell-first {
            background: @font-highlight-color !important;
          }
        }
        &:not(.rs-table-row-header):hover {
          background: initial !important;

          .rs-table-cell {
            background: initial !important;
          }
        }
        &:nth-child(11),
        &:nth-child(16),
        &:nth-child(23),
        &:nth-child(37),
        &:nth-child(45) {
          &:not(.rs-table-row-header):hover {
            background: #575757 !important;
            .rs-table-cell {
              background: #575757 !important;
            }
            .rs-table-cell-first {
              background: @font-highlight-color !important;
            }
          }

          .rs-table-cell {
            background-color: #575757 !important;
          }
          .rs-table-cell-first {
            background: @font-highlight-color !important;

            h6 {
              font-weight: bold;
              color: white;
            }
          }
        }
      }
      .rs-table-cell-group {
        .rs-table-cell:nth-child(3) {
          border: 5px solid @font-gradient-color;
          border-top: 0px;
          border-bottom: 0px;
        }
      }
      .plan-main-header {
        > div {
          background-color: #575757;
        }
        h4 {
          color: white;
        }
      }
      .plan-highlight-header {
        > div {
          background-color: @font-gradient-color;
        }
        h4 {
          color: white;
        }
      }
      button {
        font-size: 14px;
      }
    }

    #table-mobile {
      .rs-table-affix-header.fixed {
        top: 50px !important;
      }
      .rs-table-row-header {
        .plan-highlight-header,
        .plan-main-header {
          .rs-table-cell {
            left: 0 !important;
            width: 100% !important;

            .rs-btn.rs-btn-default.rs-picker-toggle {
              padding: 0;
            }
            .rs-table-cell-content {
              width: 100% !important;
              padding: 0;
              padding-top: 0.3em;
              .rs-picker-toggle-wrapper {
                width: 100% !important;
                h4 {
                  text-align: center;
                }
              }

              .rs-picker-subtle .rs-btn.active,
              .rs-picker-subtle .rs-picker-toggle.active {
                background-color: inherit;
              }
              .rs-picker-subtle .rs-btn:active:hover,
              .rs-picker-subtle .rs-picker-toggle:active:hover,
              .rs-picker-subtle .rs-btn.rs-btn-active:hover,
              .rs-picker-subtle .rs-picker-toggle.rs-btn-active:hover,
              .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:hover,
              .rs-open
                > .rs-dropdown-toggle.rs-picker-subtle
                .rs-picker-toggle:hover,
              .rs-picker-subtle .rs-btn:active:focus,
              .rs-picker-subtle .rs-picker-toggle:active:focus,
              .rs-picker-subtle .rs-btn.rs-btn-active:focus,
              .rs-picker-subtle .rs-picker-toggle.rs-btn-active:focus,
              .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:focus,
              .rs-open
                > .rs-dropdown-toggle.rs-picker-subtle
                .rs-picker-toggle:focus,
              .rs-picker-subtle .rs-btn:active.focus,
              .rs-picker-subtle .rs-picker-toggle:active.focus,
              .rs-picker-subtle .rs-btn.rs-btn-active.focus,
              .rs-picker-subtle .rs-picker-toggle.rs-btn-active.focus,
              .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.focus,
              .rs-open
                > .rs-dropdown-toggle.rs-picker-subtle
                .rs-picker-toggle.focus {
                background-color: inherit;
              }
            }
          }
        }
      }
    }
  }
  .details-plan-select {
    margin: 1em auto;
    width: 90%;
  }
}

.rs-picker-select-menu-item h4 {
  text-align: center !important;
  color: @font-gradient-color;
}

.rs-picker-select-menu-item-active {
  background-color: lightgray;

  h4 {
    font-weight: bold !important;
  }
}

.feature-table {
  width: 100%;

  .feature-header {
    background-color: @font-highlight-color;
    border-bottom: 0 !important;
    .feature-cell {
      height: 100%;
      color: white;
    }
    .feature-cell:nth-child(2),
    .feature-cell:nth-child(4) {
      background-color: #575757;
    }
    .feature-cell:nth-child(3) {
      background-color: @font-gradient-color;
    }
  }

  .feature-row,
  .feature-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(211, 211, 211, 0.437);
    .feature-cell {
      height: 100%;
      padding: 1em 0.5em;
      i {
        color: @font-highlight-color;
        font-weight: bold;
      }
      white-space: pre-wrap;
    }
    .feature-cell:not(:first-child) {
      flex-grow: 1;
      text-align: center;
      width: 20%;
    }
    .feature-cell:first-child {
      width: 40%;
      h6 {
        white-space: pre-wrap;
      }
    }
    .feature-cell:nth-child(3) {
      border-left: 5px solid @font-gradient-color;
      border-right: 5px solid @font-gradient-color;
    }

    &.highlighted {
      background-color: #575757;
      color: white;
      border-bottom: 0;
      h6 {
        color: white;
      }
      .feature-cell:first-child {
        background-color: @font-highlight-color;
      }
      .feature-cell:nth-child(3) {
        border-left: none;
        border-right: none;
      }
    }
  }

  .feature-row {
    &:last-child {
      border-bottom: 0;
      .feature-cell {
        border-bottom: 5px solid white;
      }
      .feature-cell:nth-child(3) {
        border-bottom: 5px solid @font-gradient-color;
      }
    }
  }

  &.mobile {
    .feature-header {
      width: 100% !important;
      padding: 0;
      background-color: #575757;
      &.professional {
        background-color: @font-gradient-color !important;
      }
      .rs-picker-toggle-wrapper {
        width: 100% !important;
        h4 {
          text-align: center;
          color: white;
        }
      }

      .rs-picker-subtle .rs-btn.active,
      .rs-picker-subtle .rs-picker-toggle.active {
        background-color: inherit;
      }
      .rs-picker-subtle .rs-btn:active:hover,
      .rs-picker-subtle .rs-picker-toggle:active:hover,
      .rs-picker-subtle .rs-btn.rs-btn-active:hover,
      .rs-picker-subtle .rs-picker-toggle.rs-btn-active:hover,
      .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:hover,
      .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:hover,
      .rs-picker-subtle .rs-btn:active:focus,
      .rs-picker-subtle .rs-picker-toggle:active:focus,
      .rs-picker-subtle .rs-btn.rs-btn-active:focus,
      .rs-picker-subtle .rs-picker-toggle.rs-btn-active:focus,
      .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn:focus,
      .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle:focus,
      .rs-picker-subtle .rs-btn:active.focus,
      .rs-picker-subtle .rs-picker-toggle:active.focus,
      .rs-picker-subtle .rs-btn.rs-btn-active.focus,
      .rs-picker-subtle .rs-picker-toggle.rs-btn-active.focus,
      .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-btn.focus,
      .rs-open > .rs-dropdown-toggle.rs-picker-subtle .rs-picker-toggle.focus {
        background-color: inherit;
      }
    }
    .feature-cell:not(:first-child) {
      flex-grow: 1;
      text-align: center;
      width: 40%;
    }
    .feature-cell:first-child {
      width: 60%;
    }
    .feature-row:last-child .feature-cell {
      width: 100%;
      a {
        margin: auto;
        width: 100%;
        display: flex;

        button {
          width: 98% !important;
          margin: auto;
        }
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;