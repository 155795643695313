@import (reference) "./../../App.less";

.help-options-panel {
  .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .panel-option {
      transition: box-shadow 1s border 500ms;
      border-radius: 6px;
      padding: 2em;
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 100%;
      }
      .panel-info {
        margin: 1em 0;
        display: flex;
        flex-direction: row;
        align-items: center;

        h3 {
          color: black;
        }
        img {
          width: 3em;
          margin-right: 1em;
        }
        i {
          margin-right: 0.5em;
          color: @font-highlight-color;
        }
      }
    }
  }
}

@media screen and (min-width: 750px) {
  .help-options-panel {
    .rs-panel-body {
      max-width: @max-width;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > h2 {
        color: black;
        margin-top: 2.5em;
        margin-bottom: 1em;
      }
      .options-list {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }

      .panel-option {
        width: 50%;
        margin: 1em;
        transition: box-shadow 1s border 500ms;
        border-radius: 6px;
        padding: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;

        .panel-info {
          margin: 1em 0;
          display: flex;
          flex-direction: row;
          align-items: center;

          h3 {
            color: black;
          }
          img {
            width: 3em;
            margin-right: 1em;
          }
          i {
            margin-right: 0.5em;
            color: @font-highlight-color;
          }
        }
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;