@import (reference) "./../../App.less";

.feature-card {
  margin: 2.5em 0;
  .rs-panel-body {
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
  }
  .feature-panel-text {
    > h5 {
      margin-left: 2em;
    }
    h4 {
      font-weight: normal;
      margin-left: 0em;
    }
    a {
      color: @font-highlight-color;
      &:hover {
        color: @font-gradient-color;
      }
    }
    .feature-title {
      display: flex;
      flex-direction: row;

      .feature-title-text {
        margin-left: 0.5em;
      }
      h3 {
        color: black;
      }
      .feature-index {
        color: @font-highlight-color;
      }
    }
    .feature-ul {
      margin-left: 0em;
      font-weight: normal;
    }
    .feature-button {
      margin-top: 1em;
      margin-left: 2em;
      white-space: pre-wrap;
    }
  }
  .feature-panel-img {
    margin-bottom: 1em;
    width: 100%;
    img {
      width: 100%;

      filter: drop-shadow(2px 2px 3px rgba(141, 188, 217, 0.25));
    }
  }
}

@media screen and (min-width: 1024px) {
  .feature-card {
    margin: 5em 0;
    width: 100%;
    .rs-panel-body {
      flex-direction: row;
      align-items: center;
    }
    .feature-panel-text {
      width: 50%;
      margin: 1em 0em;
      > h5 {
        margin-left: 3em;
      }

      .feature-title {
        display: flex;
        flex-direction: row;
        h3 {
          color: black;
        }
      }
      .feature-ul {
        margin-left: 1.3em;
        font-weight: normal;
      }
      .feature-button {
        margin-top: 2em;
        margin-left: 3em;

        button {
          margin: 0;
        }
      }
    }
    .feature-panel-img {
      width: 50%;
      margin: 1em;
      img {
        padding: 1em;
      }
    }
  }
  .feature-card:nth-child(2n + 2) {
    .rs-panel-body {
      flex-direction: row-reverse !important;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;