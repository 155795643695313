@import (reference) "./../../App.less";

.questions-panel {
  .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;

    h2 {
      text-align: center;
      color: black;
    }
  }
  .panel-text {
    padding: 0.5em;
    text-align: center;
    h2 {
      color: black;
    }
    h3 {
      padding-top: 0.5em;
      font-size: 2em;
      line-height: 20px;
    }
  }

  .panel-questions {
    .question {
      .rs-panel-title {
        font-weight: bold;
        margin-right: 1em;
        color: black;
      }
      button {
        margin: 0.5em 0;
        width: 100%;
      }
      .rs-panel-body h4 {
        font-weight: normal;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .questions-panel {
    .panel-questions {
      margin: 1em;
      .rs-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .question.rs-panel {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
  .pricing-link {
    float: right;
  }
}

.pricing-link {
  margin-top: 4em;
  margin-bottom: 5em;
  color: @font-highlight-color;
  text-decoration: underline;
  font-weight: 600 !important;

  &:hover {
    color: @font-gradient-color;
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;