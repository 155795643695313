@import (reference) "./../../App.less";

.header-panel {
  margin: 0em 0em;
  border-radius: 0;

  .rs-panel-body {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    max-width: @max-width;
    margin: 0 auto;

    h1 {
      width: 100%;
      color: white;
      text-align: center;
      margin: 0.5em 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .header-panel {
    .rs-panel-body {
      h1 {
        text-align: start;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;