@import (reference) "./../../App.less";

.problem-list {
  margin: 2em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    text-align: center;
    color: black;
    font-size: 1.5em;
    line-height: 30px;
  }
  h3 {
    font-size: 1em;
    line-height: 25px;
  }
  .rs-list {
    margin: 1em 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .rs-list-item {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .rs-list-item-content {
    font-size: 14px;
    font-weight: bold;
  }
}

@media screen and (min-width: 1024px) {
  .problem-list {
    margin: 2em 3em;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .problem-text {
      width: 100%;
    }
    h2 {
      font-size: 2em;
    }
    h3,
    li {
      font-size: 1.2em;
      line-height: 30px;
      font-weight: normal;
      color: black;
    }
    .rs-list {
      margin: 1em auto;
      width: 80%;
    }
    .rs-list-item-content {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;