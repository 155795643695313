@import (reference) "./../../App.less";

.popular-articles-panel {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 2em;
  border-radius: 7px;
  .rs-panel-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .panel-text {
    padding: 0.5em;
    text-align: center;
    h2 {
      color: black;
    }
    h3 {
      padding-bottom: 0.5em;
      font-size: 2em;
      line-height: 20px;
    }
  }

  .container-popular,
  .container-webinar {
    margin: 1em 0;
    width: 100%;
  }
  .panel-popular,
  .panel-webinar {
    a {
      color: @font-gradient-color;
    }
  }
}

@media screen and (min-width: 1024px) {
  .popular-articles-panel {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 2em;
    border-radius: 7px;
    .rs-panel-body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
    }
    .panel-text {
      padding: 0.5em;
      text-align: center;
      h2 {
        color: black;
      }
      h3 {
        padding-bottom: 0.5em;
        font-size: 2em;
        line-height: 20px;
      }
    }

    .container-popular,
    .container-webinar {
      margin: 0 0.5em;
    }
    .panel-popular,
    .panel-webinar {
      a {
        color: @font-gradient-color;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;