@import (reference) "./../../App.less";

.service-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10em;
  width: 10em;
  border-radius: 50%;
  border: 2px solid #15db95;
  text-align: center;
  position: relative;
  top: 70px;
  background-color: @font-gradient-color;
  left: 62%;
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
  h3 {
    color: white;
    margin: 0 0.5em;
    max-width: 100%;
    word-wrap: break-word;
  }
  h2 {
    color: rgb(255, 217, 102);
    font-weight: 800 !important;
  }
}

.service-card {
  width: 100%;
  background-color: @font-gradient-color;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 20%), 0 0 5px rgb(0 0 0 / 6%);
  .service-card-container {
    transform: rotate(10deg);
    background-position-x: -265px;
    background-position-y: -63px;
    height: 100%;
    background-size: 1000px;
    height: 100%;
    overflow: visible;
    .rs-panel-body {
      transform: rotate(-10deg);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100%;
      padding: 3em 2em;
      .highlight {
        color: @font-highlight-color;
      }
      h2,
      h3,
      h4 {
        color: white;
      }
      .service-card-info {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
      .service-description {
        width: 100%;
        margin: 2em 0;
        h4 {
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  svg {
    display: none;
    transform: rotate(9deg);
    position: relative;
    top: -400px;
    right: -172px;
  }
}

@media screen and (min-width: 750px) {
  .service-price {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 14em;
    width: 14em;
    border-radius: 50%;
    border: 2px solid #15db95;
    text-align: center;
    position: relative;
    top: 127px;
    background-color: @font-gradient-color;
    left: 65%;
    filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.5));
    h3 {
      color: white;
      margin: 0 0.5em;
    }
    h2 {
      color: rgb(255, 217, 102);
    }
  }

  .service-card {
    margin: 2em auto;
    background-color: @font-gradient-color;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 20%), 0 0 5px rgb(0 0 0 / 6%);
    .service-card-container {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1440 320'%3E%3Cpath stroke='%2315DB95' stroke-width='3' fill-opacity='0' d='M0 128L80 122.7C160 117 320 107 480 138.7C640 171 800 245 960 261.3C1120 277 1280 235 1360 213.3L1440 192L1440 0L1360 0C1280 0 1120 0 960 0C800 0 640 0 480 0C320 0 160 0 80 0L0 0Z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position-x: 75px;
      background-position-y: -20px;
      background-size: initial;
      height: 100%;
      .rs-panel-body {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
        padding: 5em 4em;
        h2,
        h3,
        h4 {
          color: white;
        }
        h2 {
          width: 67%;
        }
        .service-card-info {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .service-description {
          margin: 3em 0;
        }
      }
    }
    svg {
      display: block;
      transform: rotate(8deg) scale(1.3);
      position: relative;
      top: -508px;
      right: -126px;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;