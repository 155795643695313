@import (reference) "./../../App.less";

.service-panel {
  margin: 3em 0;
  .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;

    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;

    .panel-info {
      padding-top: 1em;
      h2,
      h3,
      h4,
      h4,
      li {
        color: black;
        margin: 0.5em 0;
      }
    }
  }
}

@media screen and (min-width: 750px) {
  .service-panel {
    margin: 3em 0;
    .rs-panel-body {
      max-width: @max-width;
      margin: 0 auto;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > div {
        width: 50%;
        padding: 0 1em;
      }

      .panel-info {
        margin-right: 2em;
        padding-top: 5em;
        h2,
        h3,
        h4,
        h5,
        li {
          color: black;
          margin: 0.5em 0;
        }
      }
    }
    &:nth-child(2n + 1) {
      .rs-panel-body {
        flex-direction: row-reverse;
        .panel-info {
          margin-left: 2em;
          margin-right: 0;
        }
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;