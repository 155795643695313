@font-face {
  font-family: 'Didact Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/didactgothic/v20/ahcfv8qz1zt6hCC5G4F_P4ASpUw.ttf) format('truetype');
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 800;
}
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
span,
button,
label {
  font-family: "Montserrat" !important;
}
h1 {
  font-size: 32px;
  font-weight: 600 !important;
  line-height: 44px;
}
h2 {
  font-size: 28px;
  font-weight: 600 !important;
  line-height: 40px;
}
h3 {
  font-size: 20px;
  font-weight: 600 !important;
  line-height: 32px;
}
h4,
li {
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 28px;
  font-weight: normal;
}
h5 {
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 26px;
  font-weight: normal;
}
h6 {
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 24px;
  font-weight: normal;
}
p {
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 20px;
  font-weight: normal;
}
button {
  font-weight: 600 !important;
}
.logo {
  font-family: "Didact Gothic" !important;
  color: black;
}
.gradient-bg {
  background: radial-gradient(circle, #0a5291 0%, #0a3458 100%);
  background: #002fa6;
}
.button-primary {
  padding: 0.5em 1em !important;
  background-color: #cc0000;
  border: 1px solid #cc0000 !important;
  color: white !important;
  padding: 0.5em 0.5em;
  color: black;
  font-weight: bold;
  font-size: 1em;
}
.button-primary:hover,
.button-primary:focus {
  background-color: #cc0000 !important;
}
.button-primary.blue {
  background-color: #002fa6 !important;
  border-color: #002fa6 !important;
}
.button-primary.blue:hover {
  background-color: white !important;
  color: #002fa6 !important;
}
.button-primary.green {
  background-color: #15db95 !important;
  border-color: #15db95 !important;
}
.button-primary:disabled {
  background-color: lightgray !important;
  border-color: lightgray !important;
  color: white !important;
}
.button-secondary {
  padding: 0.5em 1em !important;
  background-color: #15db95;
  border: 1px solid #15db95 !important;
  color: white !important;
  padding: 0.5em 0.5em;
  color: black;
  font-weight: bold;
  font-size: 1em;
}
.button-secondary:hover,
.button-secondary:focus {
  background-color: #15db95 !important;
  color: white !important;
}
.button-secondary.blue {
  background-color: rgba(0, 0, 0, 0);
  border-color: #002fa6 !important;
  color: #002fa6 !important;
}
.button-secondary.blue:hover {
  background-color: #002fa6 !important;
  color: white !important;
}
.button-secondary.bordered {
  background-color: white !important;
}
.button-secondary.bordered.green {
  color: #15db95 !important;
}
.button-secondary.bordered.green:hover {
  background-color: #15db95 !important;
  color: white !important;
}
.button-secondary:disabled {
  border-color: lightgray !important;
  color: lightgray !important;
}
.list-button-center {
  display: flex;
  justify-content: center;
  margin: 1em 0;
}
.rs-drawer-left.rs-drawer-xs,
.rs-drawer-right.rs-drawer-xs {
  width: 50% !important;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 48px;
    line-height: 60px;
  }
  h2 {
    font-size: 36px;
    line-height: 48px;
  }
  h3 {
    font-size: 28px;
    line-height: 40px;
  }
  h4,
  li {
    font-size: 20px;
    line-height: 32px;
  }
  h5 {
    font-size: 16px;
    line-height: 28px;
  }
  h6 {
    font-size: 14px;
    line-height: 26px;
  }
  p {
    font-size: 12px;
    line-height: 24px;
    font-weight: normal;
  }
}
.gradient-bg.rs-panel-shaded {
  box-shadow: 0px 10px 18px 10px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.06);
}
.legal-panel .rs-panel-body {
  max-width: 1300px;
  margin: 0 auto;
}
.legal-panel .rs-panel-body h3,
.legal-panel .rs-panel-body h4 {
  margin: 1em 0;
}
