@import (reference) "./../../App.less";

.features-panel {
  margin: 3em auto;
  max-width: @max-width;
  > .rs-panel-body {
    padding: 0 @content-margin;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .panel-features {
    width: 100%;
  }

  .panel-text {
    margin-bottom: 3.5em;
    margin-top: 5em;
    text-align: center;
    h3 {
      color: @font-highlight-color;
      line-height: 20px;
    }
  }
  .panel-img {
    position: relative;
    height: 20em;

    img {
      width: 20em;
      margin: 0 auto;
    }
  }
  .feature-footer {
    display: flex;
    align-items: center;
    h3 {
      width: 80%;
      color: black;
    }
    button {
      margin: 1em;

      h3 {
        width: 100%;
        color: white;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .features-panel {
    margin: 3em auto;

    .rs-panel-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .panel-text {
      h2 {
        color: black;
      }
      h3 {
        line-height: initial;
        text-align: center;
      }
      .brand-list {
        margin-top: 2em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        img {
          height: 5em;
        }
      }
    }
    .panel-features {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .feature-footer {
      display: flex;
      align-items: center;
      button {
        width: 50%;
        margin: 2em;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;