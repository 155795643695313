@import (reference) "./../../App.less";

.key-points-panel {
  max-width: @max-width;
  margin: 3em auto;
  .rs-panel-body {
    padding: 0 @content-margin;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .key-point {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      margin: 2em auto;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      h5 {
        width: 70%;
        margin-left: 1em;
        text-align: start;
      }
      .img-container {
        width: 5em;
        height: 5em;
        background: @font-gradient-color;
        box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        img {
          width: 3em;
          filter: invert(1) drop-shadow(1px 2px 3px rgba(1, 1, 1, 0.5));
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .key-points-panel {
    margin: 5em auto;
    .rs-panel-body {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .key-point {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 20%;

        h5 {
          width: 100%;
          margin-left: 0;
          margin-top: 1.5em;
          text-align: center;
        }
        .img-container {
          width: 5em;
          height: 5em;
          background: @font-gradient-color;
          box-shadow: 0 4px 4px rgb(0 0 0 / 12%), 0 0 10px rgb(0 0 0 / 6%);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;
          img {
            width: 3em;
            filter: invert(1) drop-shadow(1px 2px 3px rgba(1, 1, 1, 0.5));
          }
        }
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;