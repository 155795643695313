@import (reference) "./../../App.less";

.demo-request-panel {
  margin: 2em 1em;

  background-color: #efefef;

  .rs-panel-body {
    h2,
    h4 {
      text-align: center;
      margin: 0.5em 0;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .demo-request-panel {
    margin: 2em 3em;

    background-color: #efefef;

    .rs-panel-body {
      h2,
      h4 {
        text-align: start;
        margin: 0;
      }
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;