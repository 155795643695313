@import (reference) "./../../App.less";

.knowledge-base-search-panel {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 2em;
  border-radius: 7px;
  .base-search-options {
    .base-search-input {
      input {
        border: 2px solid lightgray;
        background-color: #efefef;
      }
    }
    .base-search-button {
      background-color: lightgray;
      margin-top: 1px;
      right: 2px !important;
      border-radius: 0 4px 4px 0 !important;
    }
  }
  .base-search-categories {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0.5em 0;
    width: 100%;
    button {
      width: 45%;
      margin: 0.5em 0;
    }
  }

  .base-search-results {
    h3,
    h4 {
      text-align: center;
    }
    h4 {
      font-weight: normal;
    }
    a {
      color: @font-gradient-color;
    }
  }
}

@media screen and (min-width: 1024px) {
  .knowledge-base-search-panel {
    width: 60%;
    margin: 0 auto;
    margin-bottom: 2em;
    border-radius: 7px;
    .base-search-options {
      .base-search-input {
        input {
          border: 2px solid lightgray;
          background-color: #efefef;
        }
      }
      .base-search-button {
        background-color: lightgray;
        margin-top: 1px;
        right: 2px !important;
        border-radius: 0 4px 4px 0 !important;
      }
    }
    .base-search-categories {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0.5em 0;
      width: 100%;
      button {
        width: 30%;
        margin: 0.5em 0;
      }
    }

    .base-search-results {
      .results {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      h4 {
        margin-left: 1em;
      }
      a {
        color: @font-gradient-color;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;