@import (reference) "./../../App.less";

.plan-card {
  background-color: white;
  flex-grow: 1;
  height: 1000px;

  &.categories {
    width: 15%;
    .plan-intro {
      margin-bottom: 0.3em;
    }
    .plan-stat h5 {
      text-align: start !important;
      font-weight: bold !important;
    }
  }

  margin: 0.5em 0em;

  &:nth-child(3) {
    border-radius: 0;
  }

  &.primary {
    border: 5px solid #575757;
    .plan-intro {
      background-color: #575757;
    }
  }

  &.secondary {
    border: 5px solid @font-gradient-color !important;
    border-top: 2px solid @font-gradient-color !important;
    .plan-intro {
      background-color: @font-gradient-color;
    }
    .plan-pricing {
      h3,
      h4 {
        color: @font-gradient-color;
        &.secondary {
          color: @font-gradient-color;
        }
      }
    }
  }
  &:nth-child(2) {
    border-radius: 6px;
  }
  &:last-child {
    border-radius: 6px;
  }

  &.categories {
    background-color: white !important;

    .plan-title,
    .plan-description,
    .plan-pricing,
    .plan-features {
      background-color: white;
    }
  }

  .rs-panel-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    height: 100%;
  }
  button {
    margin: 1em;
    &.plan-button {
      width: 80%;
    }
  }

  .plan-intro {
    width: 100%;
    height: 5%;
    flex-grow: 0;
    justify-content: center;
    .plan-title {
      font-weight: bold;
      text-align: center;
      color: white;
    }
    .plan-description {
      margin: 0.5em 0;
      line-height: 20px;

      padding: 1em;
      height: 12em;
      overflow-y: auto;
    }
  }

  .plan-pricing {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .rs-container {
      justify-content: center;
    }
    h3 {
      margin-top: 1em !important;
    }

    h3,
    h4 {
      text-align: center;
      font-weight: normal;
      margin: 0;

      strong {
        font-size: 1.8em;
      }
      &.secondary {
      }
    }
  }
  .plan-features {
    width: 100%;
    height: 73%;
    flex-grow: 1;
    margin-top: 0.5em;
    flex-direction: column;
    justify-content: space-between;

    .plan-basic-features,
    .plan-key-features {
      width: 100%;
      align-self: flex-start;
      box-shadow: none;
      display: flex;
      flex-direction: column;
      .plan-stat {
        div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        h3,
        h4 {
          text-align: center;
          margin: 0 0.3em;
        }
        h5 {
          text-align: center;
        }
      }
    }

    .plan-key-features {
      h5 {
        margin: 0.1em 0;
        &:first-child {
          margin-top: 0em;
        }
        &:last-child {
          margin-bottom: 0em;
        }
      }
    }

    h5 {
      padding: 0 1em;
      font-weight: normal;
      text-align: start;
      margin: 0em 0;
      margin-bottom: 0.3em;
    }
    .key-features-text {
      align-self: flex-start;
      color: black;
      padding: 0 1em;
      font-weight: bold;
    }
  }
}

@media screen and (min-width: 1024px) {
  .plan-card {
    flex-grow: 1;
    width: 30%;
    height: 900px;

    &.categories {
      width: 20%;
      .plan-intro {
        margin-bottom: 0em;
      }
      .plan-stat h5 {
        text-align: start !important;
        font-weight: bold;
        padding: 0 0.5em;
      }
    }

    margin: 0.5em 0em;

    &:nth-child(3) {
      border-radius: 0;
    }

    &.primary:first-child {
      border: none;
      .plan-intro {
        background-color: white;
      }
    }
    &.primary:not(:first-child) {
      border: 2px solid #575757;
      .plan-intro {
        background-color: #575757;
      }
    }

    &.secondary {
      border: 5px solid @font-gradient-color !important;
      border-top: 2px solid @font-gradient-color !important;
      .plan-intro {
        background-color: @font-gradient-color;
      }
      .plan-pricing {
      }
    }
    &:nth-child(1) {
      border-radius: 6px 0 0px 0px;
      border-right: 0 !important;
    }
    &:nth-child(2) {
      border-radius: 0px 0 0px 6px;
      border-right: 0 !important;
    }
    &:last-child {
      border-radius: 0px 6px 6px 0px;
      border-left: 0 !important;
    }

    &.categories {
      background-color: white !important;

      .plan-title,
      .plan-description,
      .plan-pricing,
      .plan-features {
        background-color: white;
      }
    }

    .rs-panel-body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0;
      height: 100%;
    }
    button {
      margin: 1em;
      &.plan-button {
        width: 80%;
      }
    }

    .plan-intro {
      width: 100%;
      height: 7%;
      flex-grow: 0;
      .plan-title {
        font-weight: bold;
        text-align: center;
        color: white;
      }
      .plan-description {
        margin: 0.5em 0;
        line-height: 20px;

        padding: 1em;
        height: 12em;
        overflow-y: auto;
      }
    }

    .plan-pricing {
      width: 100%;
      height: 30%;
      flex-grow: 0;
      padding: 0.5em 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3,
      h4 {
        text-align: center;
        font-weight: normal;
        margin: 0;

        strong {
          font-size: 1.8em;
        }
        &.secondary {
        }
      }

      &.cat {
      }
    }
    .plan-features {
      width: 100%;
      height: 73%;
      flex-grow: 1;
      margin-top: 0em;
      flex-direction: column;
      justify-content: space-between;
      .rs-divider {
        &:first-child {
          margin-top: 0 !important;
        }
      }

      .plan-basic-features,
      .plan-key-features {
        width: 100%;
        align-self: flex-start;
        box-shadow: none;
        display: flex;
        flex-direction: column;
        .plan-stat {
          div {
            justify-content: center;
          }
          h3,
          h4 {
            text-align: center;
            margin: 0 0.3em;
          }
          h5 {
            text-align: center;
          }
        }
      }

      .plan-key-features {
        h5 {
          &:first-child {
            margin-top: 0em;
          }
          &:last-child {
            margin-bottom: 0em;
          }
        }
      }

      h5 {
        padding: 0 1em;
        font-weight: normal;
        text-align: start;
        margin: 0em 0;
        margin: 0.1em 0;
      }
      .key-features-text {
        align-self: flex-start;
        color: black;
        padding: 0 1em;
        font-weight: bold;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;