@import (reference) "./../../App.less";

.pricing-guarantee-panel {
  margin: 1em 0.5em;

  h2 {
    text-align: center;
    margin: 1em;
  }

  .rs-panel-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
  }
  .panel-main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1em;
    border-radius: 7px;
    border: 2px solid @font-highlight-color;

    img {
      height: 8em;
    }

    span {
      padding-top: 1em;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .pricing-guarantee-panel {
    margin: 1em 1em;

    .panel-main {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      width: 60%;
      margin: 1em;
      padding: 1em;
      border-radius: 7px;
      border: 2px solid @font-highlight-color;

      img {
        height: 8em;
      }

      span {
        padding-top: 0em;
        padding-left: 1em;
        font-size: 18px;
        line-height: 25px;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;