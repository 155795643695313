@import (reference) "./../../App.less";

#demo-request-popup {
  .rs-modal-title h4 {
    font-weight: bold;
  }

  input {
    margin: 1em 0;
    border: 2px solid lightgray;
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;