@import (reference) "./../../App.less";

.footer-nav {
  background-color: @font-highlight-color;
  border-radius: 0px;
  .rs-panel-body {
    max-width: @max-width;
    margin: 1em auto;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    .rs-divider {
      background-color: gray;
      margin: 1em 0;
    }
    .footer-main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        color: black;
        img {
          width: 1.5em;
          margin-right: 0.5em;

          border-radius: 6px;
          padding: 0.1em;
        }
      }
      h6 {
        color: black;
        width: 100%;
        line-height: 20px;
        
        &:last-child {
          font-weight: 600 !important;

        }
      }
      h6 {
        color: black;
      }
      i {
        margin: 0.25em 0;
        color: black;
      }
    }

    .footer-page-list {
      margin-bottom: 2em;
      text-align: start;
      h6 {
        color: black !important;
      }
    }
  }

  .consent-manage-button {
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
  }
  .country-select-dropdown {
    border: 1px solid black;
    border-radius: 6px;
   
    margin-top: 10px;
  }
  .country-select { padding: 0px 20px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    i {
      margin-right: 0.5em;
    }
  }
}

@media screen and (min-width: 1024px) {
  .footer-nav {
    background-color: @font-highlight-color;
    border-radius: 0px;
    .rs-panel-body {
      max-width: @max-width;
      margin: 1em auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .rs-divider {
        background-color: gray;
        margin: 1em 0;
      }
      .footer-main {
        width: 50%;
        h3 {
          color: black;
          img {
            width: 1.5em;
            margin-right: 0.5em;

            border-radius: 6px;
            padding: 0.1em;
          }
        }
        h4 {
          color: black;
        }
        h6 {
          width: 100%;
          line-height: 20px;
          font-weight: bold;
          color: black;
        }
        i {
          margin: 0.25em 0;
          color: black;
        }
      }

      .footer-page-list {
        margin-bottom: 0em;
        text-align: end;
        h6 {
          color: black !important;
        }
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;