@import (reference) "./../../App.less";

.support-contacts-panel {
  margin: 1em 0.5em;

  h2 {
    text-align: center;
  }

  .rs-panel-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5em;
  }
  .contact-options {
    display: flex;
    flex-direction: column;
  }
  .contact-button {
    width: 10em;
    margin: 1em;
    font-size: 1.5em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    i {
      margin-right: 0.5em;
    }
  }
}

@media screen and (min-width: 1024px) {
  .support-contacts-panel {
    margin: 1em 1em;

    .contact-options {
      display: flex;
      flex-direction: row;
    }
    .contact-button {
      width: 10em;
      font-size: 1.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;

      i {
        margin-right: 0.5em;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;