@import (reference) "./../../App.less";

.plans-panel {
  margin: 1em 0.5em;

  .billing-cycle-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 2em 0.5em;
    h4 {
      font-size: 1em;
      font-weight: normal;
      text-align: center;
      width: 30%;

      &.selected {
        font-weight: bold;
        color: @font-gradient-color;
      }
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 3em;
      height: 1.55em;
      margin: 0 1em;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: var(--colorBG);
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 1em;
      width: 1em;
      left: 0px;
      bottom: 1px;
      border: 3px solid gray;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider.always-on:before {
      border-color: rgb(36, 172, 36) !important;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(1.5em);
      -ms-transform: translateX(1.5em);
      transform: translateX(1.5em);
      border-color: rgb(36, 172, 36);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 2em;
      border: 3px solid gray;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  h2 {
    text-align: center;
  }

  .rs-panel-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .panel-main {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1em;
    border-radius: 7px;
    border: 2px solid @font-highlight-color;

    img {
      height: 8em;
    }

    span {
      padding-left: 1em;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .panel-plans {
    width: 100%;

    .plans-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-around;
    }
  }
}

@media screen and (min-width: 1024px) {
  .plans-panel {
    margin: 1em 3em;
    .billing-cycle-switch {
      h4 {
        font-size: 1.5em;
        text-align: center;
        width: 40%;
      }
    }

    .panel-plans {
      width: 80%;
      .plans-list {
        flex-direction: row;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;