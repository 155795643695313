@import (reference) "./../../App.less";

.brands-panel {
  background-color: #efefef;

  .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;

    padding: 0;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 3em @content-margin;
      div {
        &:first-child {
          img {
            margin-top: 0;
            margin-bottom: 1em;
          }
        }
        &:nth-child(1),
        &:nth-child(3) {
          img {
            filter: invert(1) drop-shadow(1px 2px 3px rgba(1, 1, 1, 0.25));
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          img {
            height: 4em;
          }
        }
        &:nth-child(4) {
          img {
            height: 6em;
          }
        }
      }
      img {
        height: 7em;
        margin: 2em auto;
        filter: grayscale(1) drop-shadow(1px 2px 3px rgba(1, 1, 1, 0.25));
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .brands-panel {
    .rs-panel-body div {
      flex-direction: row;

      .img-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 25%;
      }
      img {
        margin: 1em auto !important;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;