@import (reference) "./../../../App.less";

.rs-table-word-wrap .rs-table-cell-content {
  white-space: pre-wrap;
  word-break: break-word;
  font-size: 20px;
}

.legal-panel h4 {
  white-space: pre-line;
}

a {
  color: @font-highlight-color;
}

.cookie-consent-container {
  .rs-panel-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;