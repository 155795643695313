@import (reference) "./../../App.less";

.feature-points-panel {
  position: relative;
  border-radius: 0px;
  padding: 3em 0;
  .rs-panel-body {
    max-width: @max-width;
    padding: 0;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > div {
      padding: 0 @content-margin;
    }
  }
  .panel-text,
  .panel-features {
    width: 100%;
  }
  .panel-text {
    .logo-img {
      width: 2em;
      margin-right: 0.5em;

      border-radius: 6px;
      padding: 0.1em;
    }
    h2,
    h3 {
      text-align: center;
    }
    h2 {
      color: white;
      strong {
        color: @font-highlight-color;
      }
    }
  }
  .panel-features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1em;
  }
  .feature-point-card {
    .rs-panel-body {
      margin: 0;
      padding: 0;
    }
  }
}

@media screen and (min-width: 1024px) {
  .feature-points-panel {
    .rs-panel-body {
    }
    .panel-features {
      justify-content: space-between;
      flex-wrap: nowrap;
      margin: 1em 0;
    }
    .panel-text {
      h3 {
        line-height: 30px;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;