@import (reference) "./../../App.less";

.pricing-intro-panel {
  margin: 1em 1em;

  .rs-panel-body {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .panel-text {
    padding: 0.5em;
    width: 100%;
    h1 {
      color: @font-title-color;
      font-size: 1.5em;
      line-height: 30px;
    }
    h3 {
      margin: 0 auto;
      padding-top: 2em;
      text-align: center;
      color: white;
      font-size: 1.2em;
      line-height: 25px;
    }
    .feature-list {
      width: 100%;
      margin-top: 1em;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      img {
        height: 5em;
        margin: 0.5em;
      }
    }
  }
  .panel-img {
    position: relative;
    height: 20em;

    img {
      width: 20em;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 1024px) {
  .pricing-intro-panel {
    margin: 1em 3em;

    .panel-text {
      max-width: 80%;
      padding: 1em 3em;
      h1 {
        color: @font-title-color;
        font-size: 4em;
        line-height: initial;
      }
      h3 {
        color: white;
        font-size: 2em;
        line-height: initial;
        width: 80%;
      }
      .brand-list {
        margin-top: 2em;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        img {
          height: 5em;
        }
      }
    }
    .panel-img {
      width: 40%;
      position: relative;
      height: 30em;

      img {
        width: 25em;
        margin: 0 auto;
        margin-top: 2em;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;