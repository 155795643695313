@import (reference) "./../../App.less";

.testimony-panel {
  padding: 5em 0;
  background-color: #efefef;
  > .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;
    padding: 0;
    & > div {
      padding: 0 @content-margin;
    }
  }
  .panel-text {
    padding: 0.5em;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    h2 {
      color: black;
      margin-bottom: 0.5em;
    }
    h3 {
      padding-top: 0.5em;
      font-size: 2em;
      line-height: 20px;
    }
  }

  .rs-carousel {
    background-color: #efefef;
    overflow: visible;
    height: initial;
    .rs-carousel-label-wrapper {
      .rs-carousel-label {
        background-color: lightgray;
      }

      input:checked ~ label {
        background-color: @font-highlight-color;
      }
    }

    .rs-carousel-toolbar {
      bottom: -15px !important;
    }
  }
  .rs-carousel-slider {
    display: flex;
  }

  .testimony-card-wrapper {
    display: flex;
    flex-direction: row;
    background-color: #efefef;
  }
  .panel-features {
    button {
      width: 80%;
      margin: 2em;
    }
  }
}

@media screen and (min-width: 1024px) {
  .testimony-panel {
    .panel-text {
      text-align: start;
      width: 100%;
    }
    .rs-carousel {
      width: 100%;
    }
    .panel-features {
      display: flex;
      align-items: center;

      button {
        width: 50%;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;