@import (reference) "./../../App.less";

#help-request-popup {
  width: 90%;
  .rs-modal-title h4 {
    font-weight: bold !important;
    color: @font-gradient-color;
  }
  .rs-modal-body {
    margin-top: 0;
    padding-bottom: 0;

    h5 {
      font-weight: bold;
      color: @font-gradient-color;
    }
  }
  input,
  textarea {
    margin: 0em 0;
    border: 2px solid lightgray;
  }

  textarea {
  }
  h5 {
    font-weight: bold;
  }
  .request-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 1em;
    > * {
      width: 100%;
    }
    h5 {
      width: 50%;
      white-space: pre-wrap;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
  button.button-primary {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  #help-request-popup {
    width: 550px !important;

    .request-item {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-bottom: 1em;
      > * {
        width: 90%;
      }
      h5 {
        width: 50%;
        white-space: pre-wrap;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;