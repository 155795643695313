@import (reference) "./../../App.less";

.plan-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
}

.plan-cycle-selection .rs-divider-horizontal {
  margin: 4px 0 !important;
}

.plan-list-container h4 {
  margin: 0;
  font-weight: 600 !important;
}
.cycle-selection {
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.cycle-selection .switch {
  margin: 0 1em;
}

.cycle-selection .selected {
  color: @font-gradient-color;
  font-weight: bolder;
}

.cycle-selection h4 {
  margin: 0.5em 0;
}

.plan-cycle-selection {
  text-align: center;

  .rs-panel-body {
    max-width: @max-width;
    margin: 0 auto;
  }
}

.plan-cycle-selection h3 {
  margin: 0;
}

.plan-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.plan-list .plan {
  background-color: #efefef;
  width: 15em;
  height: 30em;
  margin: 0.5em;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5em;
}

.plan-list .plan .pricing {
  color: @font-gradient-color;
}

.plan-list .plan .contact {
  color: @font-highlight-color;
}

.plan-list .plan .pricing h2 {
  margin: 0.2em;
}

.plan-list .plan .pricing h5 {
  margin: 0;
}

.plan button {
  margin: 1em 0;
  width: 80%;
}

.plan .plan-features {
  flex-grow: 1;
}

.plan .plan-features h5 {
  margin: 0.5em;
}

.billing-plans-container {
  max-width: 1500px;
  margin: 0 auto;
}

.plan-popular {
  margin-top: 3em;
  margin-bottom: 1.5em;
  height: 1em;
}

.plan-popular h4 {
  width: 100%;
  color: @font-gradient-color;
  margin: 0 auto;
}

.container {
  width: 100%;
  max-width: 800px;
  height: 1000px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4em;
  input[type="radio"] {
    display: none;
  }

  .card {
    position: absolute;
    width: 75%;
    height: 100%;
    left: 0;
    right: 0;
    margin: auto;
    transition: transform 0.4s ease;
    cursor: pointer;
  }

  .cards {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
  }

  #item-1:checked ~ .cards #song-3,
  #item-2:checked ~ .cards #song-1,
  #item-3:checked ~ .cards #song-2 {
    transform: translatex(-40%) scale(0.8);
    opacity: 0.7;
    z-index: 0;
  }

  #item-1:checked ~ .cards #song-2,
  #item-2:checked ~ .cards #song-3,
  #item-3:checked ~ .cards #song-1 {
    transform: translatex(40%) scale(0.8);
    opacity: 0.7;
    z-index: 0;
  }

  #item-1:checked ~ .cards #song-1,
  #item-2:checked ~ .cards #song-2,
  #item-3:checked ~ .cards #song-3 {
    transform: translatex(0) scale(1);
    opacity: 1;
    z-index: 1;

    img {
      box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
    }
  }

  #item-2:checked ~ .cards #song-3 {
    opacity: 0;
    cursor: none;
    pointer-events: none;
  }
  #item-3:checked ~ .cards #song-2 {
    opacity: 0;
    cursor: none;
    pointer-events: none;
  }

  .player {
    background-color: #fff;
    border-radius: 8px;
    min-width: 320px;
    padding: 16px 10px;
  }

  .upper-part {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    height: 36px;
    overflow: hidden;
  }

  .play-icon {
    margin-right: 10px;
  }

  .song-info {
    width: calc(100% - 32px);
    display: block;
  }

  .song-info .title {
    color: #403d40;
    font-size: 14px;
    line-height: 24px;
  }

  .sub-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .subtitle,
  .time {
    font-size: 12px;
    line-height: 16px;
    color: #c6c5c6;
  }

  .time {
    font-size: 12px;
    line-height: 16px;
    color: #a5a5a5;
    font-weight: 500;
    margin-left: auto;
  }

  .progress-bar {
    height: 3px;
    width: 100%;
    background-color: #e9efff;
    border-radius: 2px;
    overflow: hidden;
  }

  .progress {
    display: block;
    position: relative;
    width: 60%;
    height: 100%;
    background-color: #2992dc;
    border-radius: 6px;
  }

  .info-area {
    width: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    transition: transform 0.4s ease-in;
  }

  #item-2:checked ~ .player #test {
    transform: translateY(0);
  }

  #item-2:checked ~ .player #test {
    transform: translateY(-40px);
  }

  #item-3:checked ~ .player #test {
    transform: translateY(-80px);
  }
}

@media screen and (min-width: 1024px) {
  .container {
    margin-bottom: 0em;
  }
  .plan-list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 2em;
  }

  .plan-cycle-selection .plan-list-container > h4 {
    margin-bottom: 1em;
    text-align: center;
    width: 80%;
  }

  .plan-cycle-selection .rs-btn-toggle-checked {
    background-color: @font-highlight-color !important;
  }

  .plan-cycle-selection .rs-divider-horizontal {
    margin: 8px 0 !important;
  }
  .plan-popular h4 {
    width: 82%;
    margin-left: auto;
    margin-right: 0;
    color: @font-gradient-color;
    font-weight: 600 !important;
  }
  .faq-link {
    float: right;
  }
}

.faq-link {
  color: @font-highlight-color;
  text-decoration: underline;
  margin-top: 4em;
  margin-bottom: 3em;
  &:hover {
    color: @font-gradient-color;
  }
}

.coupon-announcement {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: @font-highlight-color;
  color: white;
  border-radius: 6px;
  margin: 0.5em 0;
  padding: 0.5em;

  h5 {
    margin: 0;
  }

  .rs-tag {
    margin-right: 0.5em;
    background-color: @font-gradient-color;
    color: white;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      i {
        margin-right: 0.5em;
      }
      font-weight: bold;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;