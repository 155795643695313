@import (reference) "./../../App.less";

.payment-logos-panel {
  margin-top: 3em;
  background-color: #efefef;

  .rs-panel-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    max-width: @max-width;
    margin: 0 auto;
    padding: 5em 1em;
    img {
      height: 5em;
      margin: 1em 0.5em;
      filter: drop-shadow(2px 2px 3px rgba(168, 168, 168, 0.25));
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;