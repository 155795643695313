@import (reference) "./../../App.less";

.header-message {
  width: 100%;
  .message-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: @max-width;
    padding: 0 1.25em;
  }
  .header-lang-group {
    border: none;
  }
  .header-lang-icon {
    background-color: @font-gradient-color;
    border: none;
  }
  .lang-picker {
    border: 1px solid white;
    border-radius: 6px;
    margin-right: 0.5em;

    > a {
      padding: 0.25em 0.5em;
      color: white;
      border-radius: 6px;
      &:hover,
      &:focus,
      &:active {
        color: @font-gradient-color !important;
        background-color: @font-highlight-color !important;
      }
    }
    &:hover,
    &:focus,
    &:active {
      border-color: @font-highlight-color;
      background-color: @font-highlight-color;

      > a {
        color: @font-gradient-color !important;
        background-color: @font-highlight-color !important;
      }
    }
  }
  .header-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0.5em;
    h5 {
      text-align: center;
    }
    h5:first-child {
      color: @font-base-color;
    }
    h5:last-child {
      color: @font-highlight-color;
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;