@import (reference) "./../../App.less";

.problem-panel {
  margin: 3em auto;

  max-width: @max-width;
  .rs-panel-body {
    margin: 0 auto;
    padding: 0 @content-margin;

    .problem-container {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      &.left {
        i {
          margin-top: 1.75em !important;
          font-size: 6px !important;
          color: black;
        }
      }
      &.right {
        i {
          color: lightgreen;
        }
      }
    }
    h2 {
      color: black;
      text-align: center;
      margin-bottom: 0.5em;
    }

    .problem-points {
      .problem-point {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 1em 0;
        i {
          margin-top: 0.25em;
          margin-right: 2em;
          font-size: 18px;
        }
        .check-img {
          margin-top: 0.5em;
          margin-right: 1em;
        }

        h4 {
        }
      }
    }

    .problem-image {
      display: flex;
      align-items: center;
    }

    .problem-image img {
      margin: 0 auto;
      height: 25em;
      filter: drop-shadow(2px 2px 3px rgba(141, 188, 217, 0.25));
    }
  }
}

@media screen and (min-width: 1024px) {
  .problem-panel {
    margin: 10em auto;
    &.left {
      h2 {
        width: 50%;
        margin-right: auto;
      }
    }

    &.right {
      h2 {
        width: 50%;
        margin-left: auto;
      }
    }
    .rs-panel-body {
      max-width: @max-width;
      margin: 0 auto;

      .problem-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        &.left {
          flex-direction: row;

          i {
            margin-top: 0.5em;
            color: black;
          }
        }
        &.right {
          flex-direction: row-reverse;

          i {
            color: lightgreen;
          }
        }
      }
      h2 {
        text-align: start;
      }

      .problem-points,
      .problem-image {
        width: 50%;
      }

      .problem-points {
        .problem-point {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          margin: 1em 0;

          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .problem-image {
        display: flex;
        align-items: center;
      }

      .problem-image img {
        margin: 0 auto;
      }
    }
  }
}

@base-color: #f44336;@input-bg: #efefef;@picker-menu-item-hover-bg: #efefef;